import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import * as Beufy from "buefy"
import $ from "jquery"
import filters from "./assets/js/filters"
import VueGtag from "vue-gtag";
import VueCookies from 'vue-cookies';
import CountryFlag from 'vue-country-flag';
import LoggerPlugin from './assets/js/plugins/logger-plugin';
import { LOG_LEVELS } from './assets/js/helpers/logger';


import 'buefy/dist/buefy.css'

import "@/css/athletics.scss";

Vue.config.productionTip = false

Vue.use(VueGtag, {
  appName: 'myKMZ',
  pageTrackerScreenviewEnabled: true,
  config: { id: 'G-6CWPBX1C42' }
}, router);

window.$ = Vue.prototype.$ = $;

Vue.use(filters);
Vue.use(Beufy.default);
Vue.use(VueCookies);
Vue.use(LoggerPlugin);
Vue.component('country-flag', CountryFlag);

// Make logger available in development console
if (process.env.NODE_ENV !== 'production') {
  window.$logger = {
    setLevel: (level) => store.dispatch('logger/setLogLevel', level),
    LOG_LEVELS,
    getHistory: () => store.getters['logger/logHistory'],
    clearHistory: () => store.dispatch('logger/clearHistory')
  };
}

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
