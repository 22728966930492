const LOG_LEVELS = {
  DEBUG: 0,
  INFO: 1,
  WARN: 2,
  ERROR: 3,
  NONE: 4
};

// Load log level from localStorage if available, default to INFO
const getInitialLogLevel = () => {
  try {
    const storedLevel = localStorage.getItem('kmz_log_level');
    return storedLevel !== null ? parseInt(storedLevel, 10) : LOG_LEVELS.INFO;
  } catch (e) {
    return LOG_LEVELS.INFO;
  }
};

// Define module state
const state = {
  logLevel: getInitialLogLevel(),
  logHistory: [],
  maxLogHistory: 100,
  namespaces: {}  // Store enabled/disabled state for specific namespaces
};

// Getters
const getters = {
  isLevelEnabled: (state) => (level) => state.logLevel <= level,
  isNamespaceEnabled: (state) => (namespace) => {
    if (namespace in state.namespaces) {
      return state.namespaces[namespace];
    }
    return true; // Enabled by default
  },
  logHistory: state => state.logHistory,
  currentLogLevel: state => state.logLevel
};

// Actions
const actions = {
  debug({ commit, getters }, { message, namespace, data }) {
    if (getters.isLevelEnabled(LOG_LEVELS.DEBUG) && getters.isNamespaceEnabled(namespace)) {
      console.debug(`[${namespace || 'app'}]`, message, data || '');
      commit('addToHistory', { level: 'DEBUG', message, namespace, data, timestamp: new Date() });
    }
  },
  
  info({ commit, getters }, { message, namespace, data }) {
    if (getters.isLevelEnabled(LOG_LEVELS.INFO) && getters.isNamespaceEnabled(namespace)) {
      console.info(`[${namespace || 'app'}]`, message, data || '');
      commit('addToHistory', { level: 'INFO', message, namespace, data, timestamp: new Date() });
    }
  },
  
  warn({ commit, getters }, { message, namespace, data }) {
    if (getters.isLevelEnabled(LOG_LEVELS.WARN) && getters.isNamespaceEnabled(namespace)) {
      console.warn(`[${namespace || 'app'}]`, message, data || '');
      commit('addToHistory', { level: 'WARN', message, namespace, data, timestamp: new Date() });
    }
  },
  
  error({ commit, getters }, { message, namespace, data, error }) {
    if (getters.isLevelEnabled(LOG_LEVELS.ERROR) && getters.isNamespaceEnabled(namespace)) {
      console.error(`[${namespace || 'app'}]`, message, error || '', data || '');
      commit('addToHistory', { level: 'ERROR', message, namespace, data, error, timestamp: new Date() });
    }
  },
  
  setLogLevel({ commit }, level) {
    if (level in LOG_LEVELS) {
      try {
        localStorage.setItem('kmz_log_level', LOG_LEVELS[level]);
      } catch (e) {
        // Ignore storage errors
      }
      commit('setLogLevel', LOG_LEVELS[level]);
    }
  },
  
  enableNamespace({ commit }, namespace) {
    commit('setNamespaceState', { namespace, enabled: true });
  },
  
  disableNamespace({ commit }, namespace) {
    commit('setNamespaceState', { namespace, enabled: false });
  },
  
  clearHistory({ commit }) {
    commit('clearHistory');
  }
};

// Mutations
const mutations = {
  addToHistory(state, logEntry) {
    state.logHistory.unshift(logEntry);
    if (state.logHistory.length > state.maxLogHistory) {
      state.logHistory.pop();
    }
  },
  
  setLogLevel(state, level) {
    state.logLevel = level;
  },
  
  setNamespaceState(state, { namespace, enabled }) {
    state.namespaces = { 
      ...state.namespaces,
      [namespace]: enabled 
    };
  },
  
  clearHistory(state) {
    state.logHistory = [];
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};

// Export log levels for external use
export { LOG_LEVELS };