<template>
  <div id="app" :class="{'v3': isNew}"> 
    <BackgroundOverlay :dark="loggedIn" />
    <router-view/>
    <div v-if="isBeta" class="beta-label">beta</div>
    <div v-if="!isNew" class="mobile-overlay is-hidden-tablet">
      <h4 class="h4 title is-4">
        {{ 'sorry_label' | translate }}!
      </h4>
      <FAIcon icon="mobile" />
      <div class="notif">
        {{ 'not_on_mobile_notice' | translate }}
      </div>
    </div>
  </div>
</template>

<script>
import BackgroundOverlay from "@/components/common/BackgroundOverlay.vue"
import { mapGetters } from "vuex";
import $ from "jquery";
import moment from "moment";
import FAIcon from "@/components/common/FAIcon.vue";

export default {
  name: "app",
  computed: {
    ...mapGetters({
      loggedIn: "auth/isLoggedIn",
      isBeta: "layout/getBeta",
      isNew: "layout/getIsNew"
    })
  },
  components: {
    BackgroundOverlay,
    FAIcon
  },
  mounted: function() {
    this.$log().info("App component mounted");
    window.$ = $;
    window.moment = moment;
  }
}
</script>

<style lang="scss">

// @import "@/css/global.scss";

#app {
  font-family: 'Avenir', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  max-height: 100vh;

  &.v3 {
    background-color: $newLightGreen;
  }

}

.beta-label {
    position: fixed;
    background-color: rgba(black, 0.9);
    padding: 2px 7px;
    font-size: 0.7rem;
    color: white;
    font-weight: bold;
    border-radius: 5px;
    text-transform: uppercase;
    top: 10px;
    right: 10px;
    z-index: 100;
    display: flex;
    align-items: center;
    justify-content: center;
}

#nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}

.mobile-overlay {
  
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 9999;
  background-color: $green;
  display: flex;
  flex-direction: column;
  padding: 20px;
  justify-content: center;

  .title {
    text-transform: capitalize;
  }

  .fa-icon {
    margin-bottom: 20px;
    font-size: 3rem;  
  }

  .notif {
    padding: 0px 20px;
  }

}
</style>
