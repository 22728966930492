import Vue from 'vue'
import Router from 'vue-router'
import store from "./store/"
import { myCookies } from "./helper"

Vue.use(Router)

const router = new Router({
  mode: 'history',
  routes: [
    {
      path: '/',
      name: 'home',
      redirect: "/app",
      component: () => import(/* webpackChunkName: "about" */ './views/Home.vue'),
      children: [
        {
          path: "app/:page?",
          name: "mykmzero",
          component: () => import(/* webpackChunkName: "accounts" */'./views/My.vue'),
          props: true
        },
        {
          path: 'login',
          name: 'login',
          component: () => import(/* webpackChunkName: "Login" */ './views/Login.vue'),
          beforeEnter: (to, from, next) => {
            // If user is already logged in, redirect to app
            if(store.getters["auth/isLoggedIn"]) {
              next({path: '/app'});
            } else {
              next();
            }
          }
        }
      ],
      beforeEnter: (to, from, next) => {
        console.log(to.name, from.name);
        store.dispatch("layout/setIsNew", myCookies.get("new"));
        if(to.name != "login") {
          if(store.getters["auth/isLoggedIn"]) {
            next();
          } else {
            console.log("redirecting to login");
            // Not setting nextPath anymore
            next({name: "login"});
          }
        } else {
          console.log("going to", to.name);
          next();
        }
      }
    },
    {
      path: "/new",
      name: "new",
      beforeEnter: (to, from, next) => {
        console.log("ROUTER: setting new cookie");
        myCookies.set("new", true);
        next({name: "home"});
      }
    },
    {
      path: "/old",
      name: "old",
      beforeEnter: (to, from, next) => {
        console.log("ROUTER: unsetting new cookie");
        myCookies.set("new", false);
        next({name: "home"});
      },
    }
  ]
})
  
// Add global navigation guard to handle post-login redirects
router.beforeResolve((to, from, next) => {
  // If coming from login page and going anywhere else, redirect to /app
  if (from.name === 'login' && to.name !== 'mykmzero') {
    next({path: '/app'});
  } else {
    next();
  }
});

export default router;