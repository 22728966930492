import { Logger } from '../helpers/logger';

/**
 * Vue plugin that adds a $log property to all components
 */
const LoggerPlugin = {
  install(Vue, options = {}) {
    // Add $log to Vue prototype so it's available in all components
    Vue.prototype.$log = function() {
      // Create a new logger for each component with the component name as namespace
      if (!this._logger) {
        // Use component name or fallback to 'anonymous'
        const namespace = this.$options.name || 'anonymous';
        this._logger = new Logger(namespace);
      }
      return this._logger;
    };
    
    // Add methods to enable console shortcuts
    // This allows usage like this.$log().debug('message')
    Vue.config.globalProperties = Vue.config.globalProperties || {};
    Vue.config.globalProperties.$logger = {
      debug(message, data) {
        this.$log().debug(message, data);
      },
      info(message, data) {
        this.$log().info(message, data);
      },
      warn(message, data) {
        this.$log().warn(message, data);
      },
      error(message, error, data) {
        this.$log().error(message, error, data);
      }
    };
  }
};

export default LoggerPlugin;