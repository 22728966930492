import store from '@/store';
import { LOG_LEVELS } from '@/store/modules/logger.module';

/**
 * Helper class for application logging
 * 
 * Usage examples:
 * 
 * // In a Vue component
 * this.$log().info('Message here');
 * this.$log().debug('Debug info', { someData: 'value' });
 * this.$log().error('Error occurred', error, { userId: 123 });
 * 
 * // Direct import anywhere in the application
 * import logger from '@/assets/js/helpers/logger';
 * logger.info('Message from anywhere');
 * 
 * // Custom namespace for module-specific logging
 * import { Logger } from '@/assets/js/helpers/logger';
 * const moduleLogger = new Logger('MyModule');
 * moduleLogger.info('Module message');
 * moduleLogger.warn('Warning', { someData: true });
 * 
 * // Change log level in browser console (development)
 * window.$logger.setLevel('DEBUG');
 * window.$logger.setLevel('INFO');
 * window.$logger.setLevel('WARN');
 * window.$logger.setLevel('ERROR');
 * window.$logger.setLevel('NONE'); // Turn off logging
 * 
 * // Get log history
 * window.$logger.getHistory();
 * 
 * // Clear log history
 * window.$logger.clearHistory();
 */
class Logger {
  /**
   * Create a new logger instance
   * @param {string} namespace - Namespace for this logger (usually component or module name)
   */
  constructor(namespace) {
    this.namespace = namespace || 'app';
  }

  /**
   * Log a debug message
   * @param {string} message - Message to log
   * @param {*} data - Optional data to include 
   */
  debug(message, data) {
    store.dispatch('logger/debug', {
      message,
      namespace: this.namespace,
      data
    });
  }

  /**
   * Log an info message
   * @param {string} message - Message to log
   * @param {*} data - Optional data to include
   */
  info(message, data) {
    store.dispatch('logger/info', {
      message,
      namespace: this.namespace,
      data
    });
  }

  /**
   * Log a warning message
   * @param {string} message - Message to log
   * @param {*} data - Optional data to include
   */
  warn(message, data) {
    store.dispatch('logger/warn', {
      message,
      namespace: this.namespace,
      data
    });
  }

  /**
   * Log an error message
   * @param {string} message - Message to log
   * @param {Error} error - Optional error object
   * @param {*} data - Optional data to include
   */
  error(message, error, data) {
    store.dispatch('logger/error', {
      message,
      namespace: this.namespace,
      error,
      data
    });
  }

  /**
   * Enable this namespace
   */
  enable() {
    store.dispatch('logger/enableNamespace', this.namespace);
  }

  /**
   * Disable this namespace
   */
  disable() {
    store.dispatch('logger/disableNamespace', this.namespace);
  }
}

// Static helper functions
const setLogLevel = (level) => {
  store.dispatch('logger/setLogLevel', level);
};

const getLogHistory = () => {
  return store.getters['logger/logHistory'];
};

const clearLogHistory = () => {
  store.dispatch('logger/clearHistory');
};

// Create a default logger instance
const defaultLogger = new Logger('app');

// Export helper functions and classes
export {
  Logger,
  LOG_LEVELS,
  setLogLevel,
  getLogHistory,
  clearLogHistory,
  defaultLogger
};

// Default export for direct use
export default defaultLogger;