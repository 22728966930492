const TEST = false;

// eslint-disable-next-line no-unused-vars
import _, { keys, reverse } from "lodash";
import Vue from "vue";
import moment from "moment";

// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { setDoc, collection, getFirestore, doc, query, getDocs, Timestamp, orderBy, where, limit, deleteDoc, getDoc, DocumentReference, CollectionReference } from "firebase/firestore";
import { each } from "jquery";
import { Logger } from "@/assets/js/helpers/logger";

// Create a namespaced logger for Firebase module
const logger = new Logger('firebase');

const firebaseConfig = {
    apiKey: "AIzaSyBMAbftqUcxz7xUE9vDNi0x6pajBh47I38",
    authDomain: "my-kmzero.firebaseapp.com",
    projectId: "my-kmzero",
    storageBucket: "my-kmzero.appspot.com",
    messagingSenderId: "198048815027",
    appId: "1:198048815027:web:2e16200578b09a25ea9dc6",
    measurementId: "G-NYFG9R4ENK"
};

const app = initializeApp(firebaseConfig);
const firestore = getFirestore(app);
const auth = getAuth(app);

const isObject = function(a) {
    return (!!a) && (a.constructor === Object);
};

const isArray = function(a) {
    return (!!a) && (a.constructor === Array);
};


export const fbase = {
    namespaced: true,
    state: {
        uninstalls: {},
        reinstalls: {},
        db: {},
        firestore: firestore,
        auth: auth,
        account: null,
        customer: null,
        tables: ["accounts", "customers", "equipment", "installation", "centres", "cities", "maintenance", "customer_bottles", "bottles", "kmzero_content", "comparative"],
        accounts: {},
        customers: {},
        equipment: {},
        installation: {},
        centres: {},
        cities: {},
        maintenance: {},
        customer_bottles: {},
        bottles: {},
        kmzero_content: {},
        comparitive: {},
        dataset: [],
        userReady: false,
        installations_with_data: {}
    },
    getters: {
        getFS: state => { return state.firestore; },
        getDB: state => { return state.db; },
        getRecord: state => ({ table, ref, id }) => { 
            if(!ref && (!id && !table)) return null;
            if(!ref && table && !id) return null;
            if(!ref && id && !table) return null;
            if(ref && !id) id = ref.id;
            if(id && !ref) ref = doc(state.db[table], id);
            if(!table && ref) {
                //console.log(ref);
                table = ref.parent.id;
            }

            return state[table][id]; 
        },
        getUserReady: state => {
            return state.userReady;
        },
        getTable: state => (table) => {
            return _.values(state[table]);
        },
        isMapfre: state => {
            return state.account && state.account.username === "mapfre";
        },
        getById: state => (table, recId) => {
            // Handle cases where recId is an object with id property or just an id string
            const id = recId && recId.id ? recId.id : recId;
            
            // Check if valid table and id
            if (!state[table] || !id) return null;
            
            // Find the record in the table using its id
            const found = _.find(_.values(state[table]), (record) => {
                return record && (record.id === id || record.recId === id);
            });
            
            return found || {};
        },
        getByProp: state => (table, prop, val) => {
            // Check if valid table and property
            if (!state[table] || !prop) return null;
            // Find the record in the table using the specified property and value
            const found = _.find(_.values(state[table]), (record) => {
                return record && record[prop] === val;
            });
            return found || {};
        },
        getDataset: state => {
            return state.dataset;
        }
    },
    mutations: {
        SETUP_FIREBASE: (state) => {
            _.each(state.tables, table => {
                state.db[table] = collection(firestore, table);
            })
        },
        SET_RECORD: (state, { table, id, data }) => {
            _.each(data, (val, key) => {
                if(val && val.toDate) {
                    // console.log("SET_RECORD to DATE", key, val);
                    data[key] = moment(val.toDate());
                }
            })
            if(data) data.order = _.size(state[table]);
            // console.log("SET_RECORD", table, id, data);
            Vue.set(state[table], id, data);
        },
        SET_KEY: (state, payload) => {
            var { key, val } = payload;
            if(!key) {
                _.each(payload, (v, k) => {
                    Vue.set(state, k, v);
                })
            } else {
                Vue.set(state, key, val);
            }
            
        },
        RESET_TABLE: (state) => {
            _.each(state.tables, table => {
                Vue.set(state, table, {});
            })
            Vue.set(state, "account", null);
            Vue.set(state, "customer", null);
        },
    },
    actions: {
        setupFirebase: function(context) {
            return new Promise((resolve, reject) => {
                logger.info("Setting up Firebase");
                context.commit("SETUP_FIREBASE");
                //context.dispatch("auth/setupAuth", auth, { root: true });
                resolve(true);
            })
        },
        loadTable: function(context, { table, conditions }) {
            return new Promise((resolve, reject) => {
                var coll = context.state.db[table];
                var ref = query(coll);

                logger.debug("Loading table", { table, conditions });
 
                // eslint-disable-next-line no-unused-vars
                if(conditions) each(conditions, (index, condition) => {
                    var op = condition.op || "==";
                    ref = query(ref, where(condition.key, op, condition.val));
                })

                //TODO: testing only get 10 docs
                // ref = query(ref, limit(10));

                getDocs(ref).then(snap => {
                    if(snap.empty) {
                        logger.info(`No docs found in table '${table}'`);
                        resolve();
                    } else {
                        snap.forEach(d => {
                            context.commit("SET_RECORD", { table, id: d.id, data: d.data() });
                        })
                        logger.info(`${snap.docs.length} docs found in table '${table}'`);
                        resolve();
                    }
                })
                .catch(err => {
                    logger.error(`Error loading table '${table}'`, err);
                    reject(err);
                })
            })
        },
        loadRecord: function(context, { table, id, ref, reload, rejectIfNotExists = false }) {
            return new Promise(async (resolve, reject) => {
                try {
                    // Parameter validation with better error messages
                    if(!ref && (!id && !table)) return reject(new Error("Need at least one of ref or id to proceed"));
                    if(!ref && table && !id) return reject(new Error(`Need id with table: ${table}`));
                    if(!ref && id && !table) return reject(new Error(`Need table name with id: ${id}`));
                    
                    // Normalize parameters
                    if(ref && !id) id = ref.id;
                    if(id && !ref && table) {
                        try {
                            ref = doc(context.state.db[table], id);
                        } catch (error) {
                            logger.error(`Failed to create document reference`, { table, id, error });
                            return reject(new Error(`Invalid table or id (table: ${table}, id: ${id}): ${error.message}`));
                        }
                    }
                    if(!table && ref) {
                        if (!ref.parent) {
                            logger.error("Reference has no parent", { ref });
                            return reject(new Error("Document reference is invalid (no parent)"));
                        }
                        table = ref.parent.id;
                    }

                    // Check if we have a valid table
                    if (!context.state[table]) {
                        logger.error(`Table does not exist in state`, { table });
                        return reject(new Error(`Unknown table: ${table}`));
                    }

                    // Return cached record if available and reload not requested
                    if(!reload && context.state[table][id]) {
                        logger.debug(`Using cached record`, { table, id });
                        return resolve(context.state[table][id]);
                    }

                    // Fetch document
                    logger.debug(`Fetching document`, { table, id });
                    getDoc(ref)
                    .then(dsnap => {
                        if(dsnap.exists()) {
                            var data = dsnap.data();
                            context.commit("SET_RECORD", { table, id, data });
                            resolve(data);
                        } else {
                            logger.warn(`Document does not exist`, { table, id });
                            // Create empty record structure with minimal required fields
                            const emptyData = { 
                                id: id,
                                recId: id,
                                _empty: true // Flag to indicate this is a placeholder
                            };
                            
                            // Only store empty records in state if configured to do so
                            if (!rejectIfNotExists) {
                                context.commit("SET_RECORD", { table, id, data: emptyData });
                                logger.info(`Created empty placeholder for non-existent document`, { table, id });
                                resolve(emptyData);
                            } else {
                                // Original behavior if rejection is requested
                                reject(new Error(`Document does not exist: ${table}/${id}`));
                            }
                        }
                    })
                    .catch(err => {
                        logger.error(`Error loading record`, { table, id, error: err });
                        reject(new Error(`Failed to load ${table}/${id}: ${err.message}`));
                    });
                } catch (error) {
                    logger.error("Unexpected error in loadRecord", { error });
                    reject(new Error(`Unexpected error in loadRecord: ${error.message}`));
                }
            });
        },
        loadRecords: function(context, { table, filters}) {
            return new Promise((resolve, reject) => {
                var coll = context.state.db[table];
                var ref = query(coll);
                let docs = [];

                logger.debug("Loading records", { table, filters });

                if(filters) each(filters, (index, filter) => {
                    var op = filter.op || "==";
                    ref = query(ref, where(filter.key, op, filter.val));
                })

                getDocs(ref).then(snap => {
                    if(snap.empty) {
                        logger.info(`No docs found in table '${table}'`, { filters });
                        resolve(docs);
                    } else {
                        snap.forEach(d => {
                            let data = d.data();
                            context.commit("SET_RECORD", { table, id: d.id, data });
                            data.ref = d.ref;
                            docs.push(data);
                        })
                        logger.debug(`${snap.docs.length} docs found in '${table}'`, { count: snap.docs.length });
                        resolve(docs);
                    }
                })
                .catch(err => {
                    logger.error(`Error loading records from table '${table}'`, err);
                    reject(err);
                })
            })
        },
        loadCentre: function(context, ref) {
            return new Promise(async (resolve, reject) => {
                if(!ref) return reject("No ref provided");
                const tasks = [];
                
                logger.debug("Loading centre", { refId: ref.id });
                
                context.dispatch("loadRecord", { ref }).then(async centre => {
                    if(centre) {
                        logger.debug("Centre loaded", { centreName: centre.name });
                        
                        // Get installations with centre reference in their centres array
                        const installationsQuery = await context.dispatch("loadRecords", { 
                            table: "installation", 
                            filters: [{ key: "centres", val: ref, op: "array-contains" }] 
                        });
                        
                        // Load each installation using the dedicated function
                        if(installationsQuery && installationsQuery.length > 0) {
                            logger.debug("Loading installations for centre", { 
                                centreName: centre.name, 
                                installationCount: installationsQuery.length 
                            });
                            
                            _.each(installationsQuery, installation => {
                                if(installation && installation.ref) {
                                    tasks[tasks.length] = context.dispatch("loadInstallation", installation.ref);
                                }
                            });
                        }

                        // Load city data
                        if(centre.city) {
                            logger.debug("Loading cities for centre", { 
                                centreName: centre.name, 
                                cityCount: centre.city.length 
                            });
                            
                            _.each(centre.city, city => {
                                tasks[tasks.length] = context.dispatch("loadCity", city);
                            })
                        }
                        
                        Promise.all(tasks).then(() => {
                            logger.debug("All centre data loaded", { centreName: centre.name, taskCount: tasks.length });
                            resolve(centre);
                        }).catch(err => {
                            logger.error("Error in loadCentre", { centreName: centre.name, error: err });
                            // Still resolve with what we have
                            resolve(centre);
                        });
                    } else {
                        logger.warn("No centre found", { refId: ref.id });
                        reject("No centre found for " + ref.id);
                    }
                }).catch(err => {
                    logger.error("Error loading centre", { refId: ref.id, error: err });
                    reject("Error loading centre: " + err);
                });
            });
        },
        loadReference: function(context, { ref, entityType }) {
            return new Promise(async (resolve, reject) => {
                if(!ref) return reject(`No reference provided for ${entityType || 'entity'}`);
                
                logger.debug(`Loading ${entityType || 'entity'}`, { refId: ref.id });
                
                context.dispatch("loadRecord", { ref })
                    .then(entity => {
                        if(entity) {
                            logger.debug(`${entityType || 'Entity'} loaded successfully`, { id: ref.id });
                            resolve(entity);
                        } else {
                            logger.warn(`No ${entityType || 'entity'} found`, { refId: ref.id });
                            reject(`No ${entityType || 'entity'} found for ${ref.id}`);
                        }
                    })
                    .catch(err => {
                        logger.error(`Error loading ${entityType || 'entity'}`, { refId: ref.id, error: err });
                        reject(`Error loading ${entityType || 'entity'}: ${err}`);
                    });
            });
        },
        
        loadCity: function(context, ref) {
            return context.dispatch("loadReference", { ref, entityType: "city" });
        },
        
        loadProvince: function(context, ref) {
            return context.dispatch("loadReference", { ref, entityType: "province" });
        },
        
        loadEquipment: function(context, ref) {
            return context.dispatch("loadReference", { ref, entityType: "equipment" });
        },
        
        loadMaintenance: function(context, ref) {
            return context.dispatch("loadReference", { ref, entityType: "maintenance" });
        },
        
        loadAccount: function(context, uid) {
            return new Promise(async (resolve, reject) => {
                if (!uid) return reject("No uid provided");
    
                var accountshot = await getDocs(query(context.state.db.accounts, where("username", "==", uid), limit(1)));
    
                if (accountshot.empty) {
                    return reject("No account found for " + uid);
                } else {
                    var account = accountshot.docs[0].data();
                    context.commit("SET_KEY", { key: "account", val: account });
    
                    // Map full language names to codes
                    const languageMap = {
                        "spanish": "es",
                        "english": "en"
                    };
    
                    // Set language or default to English ('en') if not found
                    const selectedLanguage = languageMap[account.default_language] || 'en';
                    context.dispatch("lang/setLang", selectedLanguage, { root: true });
    
                    resolve(account);
                }
            });
        },
        reset: function(context) {
            context.commit("RESET_TABLE");
        },
        getUserData: function(context, uid) {
            return new Promise(async (resolve, reject) => {
                if(!uid) return reject("getUserData: No uid provided");

                context.dispatch("layout/setWorking", true, { root: true });
                logger.info("Loading user data", { uid });

                var account, customer;
                var tasks = [];
                // TODO: remove before production
                
                const TEST_N = 30;

                try {
                    account = await context.dispatch("loadAccount", uid);
                } catch(err) {
                    logger.error("Failed to load account", err);
                    return reject(err);
                }

                if(account && account.customer && account.customer[0]) {
                    logger.debug("Account loaded successfully", { uid });

                    try {
                        let ac = account.customer[0];
                        if(ac) customer = await context.dispatch("loadRecord", { ref: ac });
                    } catch(err) {
                        logger.error("Failed to load customer", err);
                        return reject(err);
                    }

                    if(customer) {
                        context.commit("SET_KEY", { key: "customer", val: customer });
                        logger.debug("Customer loaded successfully", { customerId: customer.id });
                        
                        if(customer.centres) {
                            if(TEST) customer.centres = customer.centres.slice(0, TEST_N);
                            logger.info("Loading centres", { count: customer.centres.length });
                            _.each(customer.centres, centre => {
                                if(centre) {
                                    // Use loadCentre instead of loadRecord to load all related data
                                    tasks[tasks.length] = context.dispatch("loadCentre", centre);
                                }
                            });
                        }

                        // Customer bottles handling - not covered by loadCentre
                        if(customer.customer_bottles) {
                            logger.info("Loading customer bottles", { count: customer.customer_bottles.length });
                            _.each(customer.customer_bottles, customer_bottle => {
                                tasks[tasks.length] = context.dispatch("loadRecord", { ref: customer_bottle }).then(cb => {
                                    _.each(cb.bottle, bottle => {
                                        if(bottle) tasks[tasks.length] = context.dispatch("loadRecord", { ref: bottle });
                                    })
                                })
                            })
                        }

                        // KMZero content handling - not covered by loadCentre
                        if(customer.kmzero_content) {
                            logger.info("Loading KMZero content", { count: customer.kmzero_content.length });
                            _.each(customer.kmzero_content, kmzero_content => {
                                tasks[tasks.length] = context.dispatch("loadRecord", { ref: kmzero_content });
                            })
                        }

                        context.dispatch("loadTable", {table: "kmzero_content", conditions: [ { key: 'show_to_all_customers', val: true }]});
                    }
                }

                Promise.all(tasks).then(() => {
                    logger.info("All user data loaded successfully", { recordCount: tasks.length });
                    context.commit("SET_KEY", { userReady: true });
                    context.dispatch("layout/setWorking", false, { root: true });

                    // iterate thourgh state installations 
                    _.each(context.state.installation, (installation, id) => {
                        if(installation && installation.status) {
                            if(installation.status === "uninstall") {
                                context.state.uninstalls[installation.recId] = installation;
                            } else if(installation.status === "reinstall") {
                                context.state.reinstalls[installation.recId] = installation;
                            }
                        }
                    });

                    // call setupDataset before resolving
                    context.dispatch("setupDataset").then(() => {
                        resolve();
                    })
                })
            })
        },
        setupDataset: function(context) {
            return new Promise((resolve, reject) => {
                
                var tasks = [];
                // sort all centres by name and store in state
                var centres = _.sortBy(context.state.centres, "name");
                context.commit("SET_KEY", { key: "centres", val: centres });
                
                // Initialize the dataset array
                let the_data = [];

                // // trim centres two 2 for testing
                // centres = centres.slice(0, 2);

                const sorted_uninstalls = _.sortBy(context.state.uninstalls, "date");
                const sorted_reinstalls = _.sortBy(context.state.reinstalls, "date");


                var uninstalls = {};
                var reinstalls = {};
                _.each(sorted_uninstalls, (uninstall) => {
                    // get recIds of all uninstalls with the same .id
                    if(!uninstalls[uninstall.id]) uninstalls[uninstall.id] = [];
                    if(!uninstalls[uninstall.id].includes(uninstall.recId)) uninstalls[uninstall.id].push(uninstall.recId);
                })

                _.each(sorted_reinstalls, (reinstall) => {
                    // get recIds of all reinstalls with the same .id
                    if(!reinstalls[reinstall.id]) reinstalls[reinstall.id] = [];
                    if(!reinstalls[reinstall.id].includes(reinstall.recId)) reinstalls[reinstall.id].push(reinstall.recId);
                })
            

                console.log("uninstalls", uninstalls);
                console.log("reinstalls", reinstalls);

                
                // Process each centre
                _.each(centres, (centre) => {
                    if (centre && centre.city && centre.city.length > 0) {
                        var city = context.getters.getById("cities", centre.city[0]);
                        
                        // Skip if city doesn't exist
                        if (!city || _.isEmpty(city)) {
                            logger.warn("City not found for centre", { centreName: centre.name });
                            return;
                        }
                        
                        // Check if equipment exists
                        if (!centre.equipment || !centre.equipment.length) {
                            logger.warn("No equipment for centre", { centreName: centre.name });
                            return;
                        }
                        
                        _.each(centre.equipment, (e) => {
                            if (!e) return;
                            
                            var installation = context.getters.getById("installation", e);
                            if (!installation || _.isEmpty(installation)) {
                                logger.warn("No installation found", { equipmentRef: e });
                                return;
                            }
                                
                            var equipmentDocId = installation.equipment && installation.equipment.length > 0 ? installation.equipment[0] : null;
                            if (!equipmentDocId) {
                                logger.warn("No equipment ID found in installation", { installationId: installation.id });
                                return;
                            }
                            
                            var equipment = context.getters.getById("equipment", equipmentDocId);
                            
                            if (!equipment || _.isEmpty(equipment)) {
                                logger.warn("No equipment found", { equipmentDocId, installationId: installation.id });
                                return;
                            }

                            var services = [];
                            if (installation.service && installation.service.length > 0) {
                                _.each(installation.service, (service) => {
                                    if (service) {
                                        const maintenanceRecord = context.getters.getById("maintenance", service);
                                        if (maintenanceRecord) {
                                            service.status = maintenanceRecord.status ? maintenanceRecord.status.toLowerCase() : null;
                                            services.push(maintenanceRecord);
                                        }
                                    }
                                });
                            }

                            var last_service, next_service;
                            if (services.length > 0) {
                                services = _.orderBy(services, (s) => {
                                    return s && s.date ? moment(s.date).valueOf() : 0;
                                });
                                last_service = _.filter(services, (s) => {
                                    return s && s.date && moment(s.date).valueOf() < moment().valueOf();
                                });
                                if (last_service && last_service.length)
                                    last_service = _.orderBy(last_service, "date", "desc");
                                next_service = _.filter(services, (s) => {
                                    return s && s.date && moment(s.date).valueOf() > moment().valueOf();
                                });
                            }

                            // Determine installation status for the dataset
                            const installation_status = installation.status ? installation.status.toLowerCase() : '';

                            var uninstalls_history = [];
                            var reinstalls_history = [];

                            // Check if the installation is in the uninstalls or reinstalls list
                            if (uninstalls[installation.id]) {
                                let ui_ids = uninstalls[installation.id];
                                _.each(ui_ids, (ui_id) => {
                                    let uninstall = context.getters.getById("installation", ui_id);
                                    if (uninstall) {
                                        uninstall.date = uninstall.date && uninstall.date.toDate ? uninstall.date.toDate() : uninstall.date;
                                        uninstalls_history.push(uninstall);
                                    }
                                });
                            }

                            if (reinstalls[installation.id]) {
                                let ri_ids = reinstalls[installation.id];
                                _.each(ri_ids, (ri_id) => {
                                    let reinstall = context.getters.getById("installation", ri_id);
                                    if (reinstall) {
                                        reinstall.date = reinstall.date && reinstall.date.toDate ? reinstall.date.toDate() : reinstall.date;
                                        reinstalls_history.push(reinstall);
                                    }
                                });
                            }

                            var history = [...uninstalls_history, ...reinstalls_history];
                            // sort history from oldest to newest
                            history = _.sortBy(history, "date");

                            let is_latest = true;

                            if(history && history.length > 0) {
                                is_latest = false;
                                // check if the last history record.recId is the same as the installation.recId and it's installation_status is not "uninstall"
                                if(history[history.length - 1].recId === installation.recId && history[history.length - 1].status !== "uninstall") {
                                    is_latest = true;
                                }
                            }

                            let inst_data = {
                                recId: installation.recId || '',
                                city: city.name || '',
                                centre: centre.name || '',
                                province: city.province || '',
                                location: installation.location || '',
                                address: centre.address || '',
                                installation_date: installation.date || null,
                                installation_address: centre.address || '',
                                equipment_id: installation.id || '',
                                ceco: installation.ceco || '',
                                price: installation.price || 0,
                                sociedad: installation.sociedad || '',
                                dgt: centre.dgt || '',
                                equipment_name: equipment.name || '',
                                image: installation.image && installation.image.length > 0 ? installation.image[0] : false,
                                system: equipment.system || '',
                                appcc: installation.appcc || false,
                                document: installation.document || null,
                                last_service: last_service || [],
                                next_service: next_service || [],
                                services: services || [],
                                equipment,
                                installation,
                                installation_status: installation_status, // Add the status to the dataset
                                history: history || [],
                                is_latest: is_latest,
                                show: history && history.length > 0 && !is_latest ? false : true
                            }
                            

                            context.state.installations_with_data[installation.recId] = inst_data;

                            const add_to_dataset = history && history.length > 0 && !is_latest ? false : true;

                            if(add_to_dataset) {
                                the_data.push(inst_data);
                            }
                            
                        });
                    }
                });

                // iterate though the data 
                _.each(the_data, (data, index) => {
                    if(data && data.history && data.history.length > 0 && data.show) {
                        _.each(data.history, (history, hIndex) => {
                            // find the installation in the dataset
                            let found = context.state.installations_with_data[history.recId];
                            if(found) {
                                the_data[index].history[hIndex] = found;
                            } else {
                                // remove this history record from the dataset
                                the_data[index].history[hIndex] = null;
                            }
                        })
                    }
                });

                // Save dataset to state
                context.commit("SET_KEY", { key: "dataset", val: the_data });
                
                // Log the counts of uninstalled and reinstalled installations
                logger.info("Installation status tracking", {
                    uninstallCount: context.state.uninstalls.length,
                    reinstallCount: context.state.reinstalls.length
                });
                
                resolve(the_data);
            });
        },
        loadInstallation: function(context, ref) {
            return new Promise(async (resolve, reject) => {
                if(!ref) return reject("No ref provided");
                
                const tasks = [];
                context.dispatch("loadRecord", { ref }).then(installation => {
                    if(installation) {
                       
                        
                        // Load equipment for this installation
                        if(installation.equipment) {
                            _.each(installation.equipment, equipment => {
                                // use loadEquipment to load equipment
                                tasks[tasks.length] = context.dispatch("loadEquipment", equipment);
                            })
                        }
                        
                        // Load maintenance/service records
                        if(installation.service) {
                            _.each(installation.service, service => {
                                // use loadMaintenance to load maintenance
                                tasks[tasks.length] = context.dispatch("loadMaintenance", service);
                            })
                        }
                        
                        Promise.all(tasks).then(() => { 
                        
                            resolve(installation);
                        }).catch(err => {
                            console.error("Error loading installation data:", err);
                            // Still resolve with what we have
                            resolve(installation);
                        });
                    } else {
                        reject("No installation found for " + ref.id);
                    }
                }).catch(err => {
                    reject("Error loading installation: " + err);
                });
            });
        },
    }
}