/* eslint-disable no-unused-vars */
import Vue from "vue";
import _ from "lodash";
import Airtable from "airtable";
import { Logger } from "@/assets/js/helpers/logger";

const logger = new Logger('airtable');

const base = new Airtable({apiKey: "patmYMFhZxXhDVyCW.fe9c36be28bbb11553c1c3fb35c21b4f2ab106823bdf74475e15cc14ece1f540"}).base('appJVzcemoxCSOcXz');

const isObject = function(a) {
    return (!!a) && (a.constructor === Object);
};

const isArray = function(a) {
    return (!!a) && (a.constructor === Array);
};

function loadBase (table, fields) {
    return new Promise((resolve, reject) => {
        var allRecords = [];

        base(table).select().eachPage((records, nextPage) => {

            records.forEach(record => {

                allRecords.push({
                    recId: record.id,
                    ...record.fields
                })
            })

            nextPage();
        }, (err) => {
            if(err) {
                logger.error("Error loading Airtable records", err, { table });
                reject(err);
            } else {
                logger.debug("Airtable records loaded", { table, count: allRecords.length });
                resolve(allRecords);
            }
        })
    })
}


export const airtable = {
    namespaced: true,
    state: {
        devices: {},
        accounts: [],
        customers: [],
        equipment: [],
        installation: [],
        centres: [],
        cities: [],
        maintenance: [],
        bottles: [],
        customer_bottles: [],
        kmzero_content: [],
        comparative: [],
        tables: ["accounts", "customers", "equipment", "installation", "centres", "cities", "maintenance", "customer_bottles", "bottles", "kmzero_content", "comparative"]
    },
    getters: {
        getTable: (state) => (table) => {
            return state[table];
        },
        getDeviceData: (state) => (deviceId) => {
            return state.devices[deviceId]
        }
    },
    mutations: {
        SET_TABLE: function(state, {table, data}) {
            
            if(!state[table]) {
                logger.error("Invalid table name", { table });
                return;
            }

            if(isObject(data)) {
                //console.log(table, "data is object");
                var foundIndex = _.findIndex(state[table], d => { return d.recId === data.recId; });
                if(foundIndex > -1) {
                    _.each(data, (val, key) => {
                        Vue.set(state[table][foundIndex], key, val);
                    })
                } else {
                    state[table].push(data);
                }
            } else if(isArray(data)) {
                //console.log(table, "data is array");
                _.each(data, datum => {
                    var foundIndex = _.findIndex(state[table], d => { return d.recId === datum.recId; });
                    if(foundIndex > -1) {
                        _.each(datum, (val, key) => {
                            Vue.set(state[table][foundIndex], key, val);
                        })
                    } else {
                        state[table].push(datum);
                    }
                })
            } else {
                logger.error("Data type error", { type: typeof data, data });
            }

        },
        SET_DEVICE_DATA: function(state, {deviceId, data}) {
            state.devices[deviceId] = data;
        }
    },
    actions: {
        loadData: function(context, tables) {
            return new Promise(async (resolve, reject) => {

                if(!tables) tables = context.state.tables;

                //console.log({tables});

                _.each(tables, async table => {
                    //console.log(table, "loading");
                    await loadBase(table).then(equipment => {
                        context.commit("SET_TABLE", {table, data: equipment});
                        //console.log(table, "loaded");
                    })
                    .catch(err => {
                        logger.error("Error loading table", err, { table });
                        reject(err);
                    })

                    resolve();
                })
                
            })
        },
        reset: function(context, notTables) {
            _.each(context.state.tables, table => {
                if(notTables && notTables.includes(table)) {
                    logger.debug("Keeping table data during reset", { table });
                } else {
                    context.state[table] = [];
                }
            })
        },
        loadDeviceData: function(context, { deviceId, time } = {}) {
            return new Promise((resolve, reject) => {
                if(!deviceId) return reject("No deviceId specified");
                
                if(!time) time = "year";

                var url = `https://api.droople.com/telemetry?asset_ids=${deviceId}&aggregation=${time}`

                logger.debug("Fetching device data", { deviceId, url, time });

                fetch(url, 
                    { 
                        headers: {
                            "x-api-key": "eCc5TibMrw4XJPOUCpg6a6xXeyICLDZWThG5QDvj"
                        },
                        
                    } 
                ).then( resp => resp.json()).then( data => {
                    logger.debug("Device data received", { deviceId, dataAvailable: !!data.data });
                    if(data.data) context.commit("SET_DEVICE_DATA", { deviceId, data: data.data });
                },
                response => {
                    logger.error('Error loading device data', response, { deviceId });
                })
            })
        }
    }
}